import $ from 'jquery'
const $doc = $(document)

function italicizeSubstring() { 
  $('.js-italicize').length && $('.js-italicize').each(function() {
    const text = $(this).text()
    const word = $(this).data('italicize')
    const textWithItalics = text.replace(word, `<i>${word}</i>`)

    console.log(text, word, textWithItalics)
    $(this).html(textWithItalics)
  })
}
function unwrapMenuItemNoLinks() {
  $('.js-nolink').each(function() {
    const text = $(this).find('a:first').text()
    $(this).find('a:first').remove()
    $(this).prepend(`<span>${text}</span>`)
  })
}

function setLangValue(value, time) {
  time = !time ? 250 : time
  setTimeout(function() {
    if (value.startsWith('en')) {
      $(`.goog-te-combo option[value=""]`).replaceWith($('<option selected value="en">EN</option>'))
    } else {
      $(`.goog-te-combo option[value="${value}"]`).attr('selected')
      $(`.goog-te-combo option[value="${value}"]`).text(value)
    }
    $('.goog-te-combo').addClass('shown')
  }, time)
}

function initGoogleTranslateFormat() {
  $(window).on('load', () => {
    setTimeout(function() {
    const lang = $('html').attr('lang')
    setLangValue(lang, 150)
    }, 200)

    $('.goog-te-combo').on('change', function() {
      const value = this.value
      $('.goog-te-combo').removeClass('shown')
      setLangValue(value)
    })
  })
}

export default function miscFns(){
  unwrapMenuItemNoLinks()
  italicizeSubstring()
  !$('.page-template-campaign').length && initGoogleTranslateFormat()
}
